import { theme } from '@vfs-digital-channels/ns-react-components';
import {
  Form,
  Formik
} from 'formik';
import React, {
  useEffect,
  useMemo
} from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilCallback, useRecoilState, useResetRecoilState } from 'recoil';
import { ThemeProvider } from 'styled-components';
import { getAuthorization } from '../../../api/vodaLend';
import {
  FormSubmitHelper,
  PersonalDetailsComponent,
  SupportPopup,
  personalDetailsValidationSchema
} from '../../../components';
import { Typography } from '../../../components/typography/typography';
import {
  FormContainer,
  Gender,
  Position,
  Titles,
  submissionStates
} from '../../../constants';
import {
  CountryListInput,
  mapValues,
  stripEmpty
} from '../../../helpers';
import { findFirstValidationError } from '../../../helpers/formik';
import { useFindErrorOnState } from '../../../hooks/useFindErrorOnState';
import { authDetail, personalDetailsState, personalDetailsSubmit } from '../../../recoil';
import { useTealium } from '../../../tealium/useTealium';
import styles from './PersonalDetails.module.css';

export const PersonalDetails = () => {
  const [details, setPersonalDetails] = useRecoilState(personalDetailsSubmit);
  const { submitState, setSubmitState } = useOutletContext();
  const reset = useResetRecoilState(personalDetailsState);
  const errorInSubmittedDetails = useFindErrorOnState([personalDetailsSubmit], () => {
    reset();
    setSubmitState(submissionStates.canSubmit);
  });
  const location = useLocation();

  const formDetails = useMemo(() => (details ? {
    ...details,
    idType: details.idType || 'id',
    title: Titles.find((t) => t.value === details.title),
    positionInCompany: Position.find((p) => p.value === details.positionInCompany),
    gender: Gender.find((g) => g.value === details.gender),
    passportNumber: details.passportNumber || '',
    idNumber: details.idNumber || '',
    passportCountry: CountryListInput.find((c) => c.value === (details.passportCountry || 'ZA')),
    Gender: details.gender
  } : {}), [details]);
  const { track, events } = useTealium();
  let formTouched = false;

  const handleFormFocus = () => {
    if (formTouched) {
      return;
    }

    formTouched = true;
    track(events.personalDetails.formStart());
  };

  const formatFormData = (formData) => mapValues(stripEmpty(formData));

  const formSubmit = async (formValues) => {
    const identityType = formValues.idType === 'id' ? 'SA ID Number' : 'Passport';
    track(events.personalDetails.formEnd(identityType));
    const submission = formatFormData(formValues);
    setPersonalDetails(submission);
    await updateNames(submission);
    return details;
  };

  const setTokenData = useRecoilCallback(({ snapshot, set }) => async (data) => {
    set(authDetail, data);
    return await snapshot.getPromise(authDetail);
  }, []);

  const updateNames = async (submittedData) =>{
    let tokenData = await getAuthorization();
    const mapTokenData = {
      ...tokenData,
      firstName: submittedData.firstName,
      lastName: submittedData.surname
    };
    setTokenData(mapTokenData);       
  }

  useEffect(() => {
    track(events.personalDetails.pageView());
  }, []);

  useEffect(() => {
    if (submitState === submissionStates.submitting && !errorInSubmittedDetails?.contents) {
      setSubmitState(submissionStates.submitSuccess);
    }
  }, [submitState, errorInSubmittedDetails]);

  const handleFormikTouchedErrors = (touched, errors) => {
    const errorMessage = findFirstValidationError(errors, touched);

    if (!errorMessage) {
      return;
    }

    track(events.error('form', location.pathname, errorMessage));
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <Typography level={3} family={'medium'} align={'center'} className={styles.titleHeading} spacing={5}>
          We would like to know more about you
        </Typography>
        <Typography size={'b1'} align={'center'} className={styles.titleSubHeading} >
          {'Please add the details of a business employee with legal signing rights \n(an authorised business representative).'}
        </Typography>
      </div>

      <ThemeProvider theme={theme.light}>
        <Formik
          initialValues={formDetails}
          onSubmit={formSubmit}
          validationSchema={personalDetailsValidationSchema}
          validateOnMount={true}
        >
          {({ errors, touched }) => {
            handleFormikTouchedErrors(touched, errors);

            return (
              <Form style={FormContainer} onFocus={handleFormFocus}>
                <FormSubmitHelper submitState={submitState} setSubmitState={setSubmitState} />

                <PersonalDetailsComponent
                  namePrefix=''
                  showDob={true}
                  showGender={true}
                  showIdentity={true}
                  phoneEmailEditable={false}
                  identificationEditable={false}
                  idNumberEditable = {(formDetails?.idNumber || formDetails?.passportNumber) ? false : true}
                  dobEditable = {formDetails?.dateOfBirth ? false : true}
                  genderEditable = {formDetails?.gender?.value ? false : true}
                />
              </Form>
            );
          }}
        </Formik>
      </ThemeProvider>
      <SupportPopup />
    </div>
  );
};
