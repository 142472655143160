import {
  Accounts,
  Applications,
  BTAAccount,
  BTAFundingQuote,
  BusinessAddressDetails,
  BusinessDetails,
  BusinessDirectorDetails,
  BusinessFinancialDetails,
  CapitalFacility,
  DocumentDetails,
  FinancialDetails,
  FundingDetails,
  FundingQuote,
  Home,
  LoanApplication,
  Login,
  Logout,
  IdentityVerification,
  PersonalAddress,
  PersonalDetails,
  Profile, ProfileBankDetails,
  ProfileBusinessAddress,
  ProfileBusinessDetails, ProfileDirectorDetails, ProfileDocumentDetails,
  ProfileHome,
  ProfilePersonalAddress,
  ProfilePersonalDetails,
  Registration,
  SecurityQuestions,
  SubmitApplication,
  Withdrawal
} from '../pages';
import { Navigate } from 'react-router-dom';
import React from 'react';

export const routes = {
  logout: {
    path: '/logout',
    element: <Logout />
  },
  demo: {
    path: '/demo',
    element: <Home />
  },
  login: {
    path: '/login',
    element: <Login />
  },
  registration: {
    path: '/registration',
    element: <Registration />
  },
  IdentityVerification: {
    path: '/identityVerification',
    element: <IdentityVerification />
  },
  home: {
    path: '/',
    element: <Navigate to='/applications' />
  },
  loanApplication: {
    path: '/apply',
    element: <LoanApplication />,
    children: [
      { path: '', element: null },
      { path: 'personalDetails', element: <PersonalDetails /> },
      { path: 'personalAddress', element: <PersonalAddress /> },
      { path: 'businessDetails', element: <BusinessDetails /> },
      { path: 'businessAddress', element: <BusinessAddressDetails /> },
      { path: 'businessDirectors', element: <BusinessDirectorDetails /> },
      { path: 'businessFinancial', element: <BusinessFinancialDetails /> },
      { path: 'documentDetails', element: <DocumentDetails /> },
      { path: 'financialDetails', element: <FinancialDetails /> },
      { path: 'fundingDetails', element: <FundingDetails /> }
    ]
  },
  submit: {
    path: '/submit',
    element: <SubmitApplication />
  },
  accounts: {
    path: '/accounts',
    element: <Accounts />,
    children: [
      { path: 'bta', element: <BTAAccount /> },
      { path: 'capital-facility', element: <CapitalFacility /> },
      { path: 'capital-facility/withdrawal', element: <Withdrawal /> }
    ]
  },
  applications: {
    path: '/applications',
    element: <Applications />
  },
  fundingQuote: {
    path: '/quote',
    element: <FundingQuote />,
    children: [
      { index: true, element: <BTAFundingQuote /> }
    ]
  },
  verify: {
    path: '/verify',
    element: <SecurityQuestions />
  },
  quoteVerify: {
    path: '/quote/verify',
    element: <SecurityQuestions />
  },
  rcfVerify: {
    path: '/rcf/verify',
    element: <SecurityQuestions />
  },
  profile: {
    path: '/profile',
    element: <Profile />,
    children: [
      { path: '', element: <ProfileHome /> },
      { path: 'personalDetails', element: <ProfilePersonalDetails /> },
      { path: 'personalAddress', element: <ProfilePersonalAddress /> },
      { path: 'businessAddress', element: <ProfileBusinessAddress /> },
      { path: 'businessDetails', element: <ProfileBusinessDetails /> },
      { path: 'directorDetails', element: <ProfileDirectorDetails /> },
      { path: 'documentDetails', element: <ProfileDocumentDetails /> },
      { path: 'bankDetails', element: <ProfileBankDetails /> }
    ]
  },
  default: {
    index: true,
    element: <Applications />
  }
};
