import {
  FormikInput,
  FormikSelect
} from '@vfs-digital-channels/ns-react-components';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, {
  useMemo
} from 'react';
import { useRecoilValue } from 'recoil';
import {
  FormField,
  FormFieldError
} from '../../constants';
import { CountryListInput } from '../../helpers';
import { useIsMobile } from '../../recoil';
import { FormRadioGroup } from '../FormRadioGroup/FormRadioGroup';
const styles = {
  countryOfIssueField: {
    width: '100%'
  },
  mobileCountryOfIssueField: {
    width: '100%'
  }
};

export const Identity = ({ namePrefix, idNumberEditable}) => {
  const { values, errors } = useFormikContext();
  const isMobile = useRecoilValue(useIsMobile);

  const pref = useMemo(() => namePrefix ? `${namePrefix}.` : '', [namePrefix]);
  const idType = useMemo(() => (namePrefix ? namePrefix.split('.').reduce((sub, idx) => sub[idx], values) : values)?.idType, [values, namePrefix]);

  const idNumberStyle = useMemo(() => {
    if (idNumberEditable) {
      return FormField;
    }
  
    return {
      ...FormField,
      pointerEvents: 'none'
    };
  }, [idNumberEditable]);

  return (
    <>
      {
        idType === 'id'
          ?
          <div style={idNumberStyle}>
            <FormikInput
              label='SA ID number'
              name={`${pref}idNumber` }
              disabled={!idNumberEditable}
            />
          </div>
          :
          <div style={idNumberStyle}>
            <FormikInput
              label='Passport number'
              name={`${pref}passportNumber`}
              disabled={!idNumberEditable}
            />
          </div>
      }
      <FormRadioGroup 
        name={`${pref}idType`} 
        values={{
          id: 'SA ID Number',
          pp: 'Passport'
        }} 
        style={idNumberStyle} 
        disabled={!idNumberEditable}
      />

      {idType !== 'id' &&
        <div style={{
          ...(errors?.passportCountry ? FormFieldError : FormField),
          ...(isMobile ? styles.mobileCountryOfIssueField : styles.countryOfIssueField)
        }}>
          <FormikSelect
            label='Country of issue'
            name={`${pref}passportCountry`}
            options={CountryListInput}
          />
        </div>
      }
    </>
  );
};

Identity.propTypes = {
  namePrefix: PropTypes.string.isRequired,
  idNumberEditable : PropTypes.bool
};