import * as yup from 'yup';
import {
  isValidCheckSum,
  nonRsaPassportRegex,
  rsaIdRegex
} from '../../constants';

export const ValidationSchema = yup.object({
  idNumber: yup.string().when('idType', {
    is: 'id',
    then: () => yup.string().matches(rsaIdRegex, 'SA ID Number is not valid')
      .test('idNumber', 'SA ID Number is not valid', (item) => isValidCheckSum(item))
      .required('SA ID Number is a required field'),
    otherwise: () => yup.string().nullable().optional()
  }),
  passportNumber: yup.string().when('idType',
    {
      is: 'pp',
      then: () => yup.string().matches(nonRsaPassportRegex, 'Passport Number is not valid').required('Passport number is a required field'),
      otherwise: () => yup.string().nullable().optional()
    }
  )
});
